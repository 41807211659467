import React from 'react'
import { graphql } from 'gatsby'
import GraphQLErrorList from '../components/graphql-error-list'
import Section from '../components/section'
import SEO from '../components/seo'
import LayoutContainer from '../containers/layout'

export const query = graphql`
  query SectionTemplateQuery($id: String!, $language: String) {
    localizedSiteSettings: sanitySiteSettings(language: { eq: $language }) {
      id
      title
      language
      description
      aboutThisProject
      activityCorner
      aResourceSiteBy
    }
    localizedSections: allSanitySection(
      filter: { slug: { current: { ne: null } }, language: { eq: $language } }
    ) {
      edges {
        node {
          id
          _id
          language
          title
          slug {
            current
          }
        }
      }
    }

    section: sanitySection(id: { eq: $id }) {
      id
      _id
      title
      language
      slug {
        current
      }
      topics {
        _rawContent(resolveReferences: { maxDepth: 20 })
        content {
          ... on SanityButton {
            _key
            _type
            label
            buttonType {
              condition
              _type
              linkExternal
              linkInternal {
                ... on SanitySection {
                  id
                  slug {
                    current
                  }
                  title
                  _id
                }
                ... on SanityTopic {
                  id
                  _id
                  title
                  slug {
                    current
                  }
                }
              }
              linkMailTo {
                _type
                email
                subject
              }
            }
          }
          ... on SanityBlockContentObject {
            _key
            _type
            content {
              style
              list
              _key
              children {
                marks
                text
                _key
              }
            }
          }
          ... on SanityAccordion {
            _key
            _type
            items {
              _key
              _type
              title
              content {
                _key
                _type
                content {
                  _type
                  _key
                  list
                  style
                  children {
                    text
                    marks
                    _type
                    _key
                  }
                }
              }
            }
          }
        }
        _id
        _key
        title
        slug {
          current
        }
      }
    }
  }
`

const SectionTemplate = (props) => {
  const { data, errors, pageContext } = props
  const { section, localizedSections, localizedSiteSettings } = data
  const { language } = pageContext
  // console.log('SectionTemplate props', props)

  const whiteHeaderText = section.slug.current !== 'building-strength-and-capacity'

  return (
    <LayoutContainer
      language={language}
      sections={localizedSections}
      siteSettings={localizedSiteSettings}
      whiteText={whiteHeaderText}
    >
      {errors && <SEO title="GraphQL Error" />}
      {section && <SEO title={section.title || 'Untitled'} />}

      {errors && <GraphQLErrorList errors={errors} />}
      {section && <Section {...section} />}
    </LayoutContainer>
  )
}

export default SectionTemplate
