import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tab,
  Tabs,
  Typography,
  useMediaQuery
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import { useLocation, useMatch, useNavigate } from '@reach/router'
import clsx from 'clsx'
import React, { useEffect, useRef, useState } from 'react'
import { localizePath } from '../../../utils/localizePath'
import normalizeId from '../../../utils/normalizeId'
import slugify from '../../../utils/slugify'
import strengthImage from '../assets/section-building-strength-and-capacity.jpg'
import selImage from '../assets/section-social-emotional-learning.jpg'
import traumaImageTablet from '../assets/section-understanding-stress-and-trauma-tablet.jpg'
import traumaImage from '../assets/section-understanding-stress-and-trauma.jpg'
import theme from '../styles/theme'
import Content from './Content'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '[image] 5fr [content] 9fr',
    gap: theme.custom.layout.gap,
    height: 'calc(var(--vh) * 100)',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      height: 'auto',
      overflowY: 'auto',
      paddingBottom: 40
    }
  },
  imageContainer: {
    gridColumn: 'image',
    [theme.breakpoints.down('sm')]: {
      height: '75vh'
    }
  },
  contentContainer: {
    gridColumn: 'content',
    height: 'calc(var(--vh) * 100)',
    display: 'grid',
    overflow: 'hidden',
    paddingTop: 118,

    [theme.breakpoints.down('sm')]: {
      display: 'grid',
      gridTemplateColumns: '1fr [contentInner] 6fr 1fr',
      height: 'auto',
      overflowY: 'auto',
      paddingTop: 48
    },
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      paddingTop: 32,
      paddingLeft: 20,
      paddingRight: 20
    }
  },
  contentInner: {
    gridColumn: 'contentInner',
    display: 'grid',
    gridTemplateRows: '[contentHeader] min-content [contentContent] 1fr',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      height: 'auto',
      overflowY: 'auto'
    }
  },
  contentHeader: {
    gridRow: 'contentHeader',
    marginBottom: '2em',
    display: 'grid',
    gridTemplateColumns: '1fr [contentHeaderInner] 7fr 1fr',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  },
  contentHeaderInner: {
    gridColumn: 'contentHeaderInner'
  },
  tabPanel: {
    gridRow: 'contentContent',
    overflowY: 'auto',
    display: 'grid',
    gridTemplateColumns: '1fr [tabPanelInner] 7fr 1fr',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      paddingBottom: '6em'
    }
  },
  tabPanelScrolled: {
    borderTop: '1px solid black'
  },
  tabPanelInner: {
    gridColumn: 'tabPanelInner',
    paddingBottom: '4em'
  },
  img: {
    objectFit: 'cover',
    width: '100%',
    height: '100%'
  },
  title: {
    marginBottom: '1em'
  },
  accordion: {
    border: 'none',
    '& .MuiCollapse-entered': {
      borderBottom: `4px solid ${theme.custom.colors.yellow}`
    },
    '& .MuiAccordion-root .MuiCollapse-entered': {
      borderBottom: 'none'
    }
  },
  accordionSummary: {
    padding: 0,
    '&.Mui-expanded': {
      minHeight: '0'
    }
  },
  accordionSummaryContent: {
    alignItems: 'center',
    justifyContent: 'space-between',
    '& svg': {
      width: 16,
      height: 16
    },
    '& .expanded': {
      display: 'none'
    },
    '&.Mui-expanded': {
      marginTop: 12,
      marginBottom: 30,
      '& h2': {
        borderBottom: `4px solid ${theme.custom.colors.yellow}`
      },
      '& .expanded': {
        display: 'block'
      },
      '& .collapsed': {
        display: 'none'
      }
    }
  }
}))

export default function Section(props) {
  const { _id, title, slug, topics, language } = props
  const normalizedId = normalizeId(_id)
  const [tabsValue, setTabsValue] = useState(normalizeId(topics[0]._id))
  const classes = useStyles()
  const matchTopicPath = useMatch('/:section/:topic')
  const location = useLocation()
  const navigate = useNavigate()
  const mediaSmall = useMediaQuery(theme.breakpoints.down('xs'))
  const mediaTablet = useMediaQuery(theme.breakpoints.only('sm'))
  const tabPanelEl = useRef(null)

  const getTopicSlug = topic => {
    const { slug: topicSlug } = topic
    let topicSlugCurrent = ''
    if (topicSlug) {
      topicSlugCurrent = topicSlug.current
    } else {
      topicSlugCurrent = slugify(topic.title)
    }
    return topicSlugCurrent
  }

  const findTopicBySlug = targetSlug => {
    return topics.find(topic => {
      if (!topic) return false
      return targetSlug === getTopicSlug(topic)
    })
  }

  const matchedTopic = matchTopicPath && matchTopicPath.topic ? matchTopicPath.topic : ' '
  // const matchedTopic = matchTopicPath?.topic || ' '

  useEffect(() => {
    if (matchTopicPath) {
      const matchingTopic = findTopicBySlug(matchTopicPath.topic)
      if (matchingTopic) {
        setTabsValue(normalizeId(matchingTopic._id))
      }
    }
  }, [matchedTopic])

  let tabPanelScrolled = false
  useEffect(() => {
    if (tabPanelEl) {
      tabPanelScrolled = tabPanelEl.scrollTop > 0
    }
  }, [tabPanelEl.current])

  const getImageSrc = () => {
    switch (slug.current) {
      case 'building-strength-and-capacity':
        return strengthImage
      case 'social-emotional-learning':
        return selImage
      case 'understanding-stress-and-trauma':
        if (mediaTablet) {
          return traumaImageTablet
        } else {
          return traumaImage
        }
    }
  }

  const getTopicById = _id => {
    return topics.find(topic => normalizeId(topic._id) === _id)
  }

  const activeTopic = getTopicById(tabsValue)

  const handleTabsChange = (event, newValue) => {
    setTabsValue(newValue)
  }

  const pushTabHistory = topic => {
    navigate(
      localizePath({ languageId: language, path: `/${slug.current}/${getTopicSlug(topic)}` })
    )
  }

  const renderTabs = () => {
    return topics.map(topic => {
      if (!topic) return null
      const normalizedTopicId = normalizeId(topic._id)
      return (
        <Tab
          key={normalizedTopicId}
          value={normalizedTopicId}
          label={topic.title}
          onClick={() => pushTabHistory(topic)}
        />
      )
    })
  }

  const renderAccordion = () => {
    return topics.map(topic => {
      if (!topic) return null
      const normalizedTopicId = normalizeId(topic._id)
      return (
        <Accordion key={normalizedTopicId} className={classes.accordion}>
          <AccordionSummary
            className={classes.accordionSummary}
            classes={{
              content: classes.accordionSummaryContent
            }}
          >
            <Typography variant="h2">{topic.title}</Typography>
            <div className={classes.accordionSummaryIcon}>
              <AddIcon className="collapsed" />
              <RemoveIcon className="expanded" />
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Content topic={topic} blocks={topic._rawContent || []} />
          </AccordionDetails>
        </Accordion>
      )
    })
  }

  const handleTabPanelScroll = e => {
    tabPanelScrolled = e.target.scrollTop > 0
  }

  return (
    <div className={classes.root}>
      <div className={classes.imageContainer} style={{ backgroundImage: `src(${getImageSrc()})` }}>
        <img className={classes.img} src={getImageSrc()} />
      </div>
      <div className={classes.contentContainer}>
        <div className={classes.contentInner}>
          <div className={classes.contentHeader}>
            <div className={classes.contentHeaderInner}>
              <Typography variant="h1" className={classes.title}>
                {title}
              </Typography>
              {!mediaSmall && (
                <Tabs value={tabsValue} onChange={handleTabsChange}>
                  {renderTabs()}
                </Tabs>
              )}
            </div>
          </div>
          {!mediaSmall && (
            <div
              ref={tabPanelEl}
              className={clsx(classes.tabPanel, tabPanelScrolled && classes.tabPanelScrolled)}
              onScroll={e => {
                console.log('scroll', e, e.target, e.target.scrollTop)
              }}
            >
              <div className={classes.tabPanelInner}>
                {activeTopic && activeTopic._rawContent && (
                  <Content topic={activeTopic} blocks={activeTopic._rawContent || []} />
                )}
              </div>
            </div>
          )}
          {mediaSmall && renderAccordion()}
        </div>
      </div>
    </div>
  )
}
